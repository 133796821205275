import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import { vhToDp, vwToDp } from '../../../utilities/responsive';
import Page from '../../containers/Page';
import AddToolStatusForm from '../../containers/Form/ToolStatus/AddToolStatusForm';

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

function AddToolStatusPage(props) {
  const {
    getRolesThunk,
    resetUserInputAction,
    setAllowEditUserAction,
    setToolStatusInputAction,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getRolesThunk();
      resetUserInputAction();
      setAllowEditUserAction(true);
      setToolStatusInputAction({
        TSCode: '',
        TSDescription: '',
        OEMid: '',
        EngineFamilyID: '',
        istoolUsable: false,
      });
    }
  }, [isFocused]);

  return (
    <Page.Protected>
      <Container>
        <AddToolStatusForm {...props} />
      </Container>
    </Page.Protected>
  );
}

export default AddToolStatusPage;
