import { useStoreActions, useStoreState } from "easy-peasy";
import CustomModal from "../../presentational/Modal";
import Button from "../Button";
import Col from "../../presentational/Col";
import Input from "../../presentational/Input";
import { vhToDp } from "../../../utilities/responsive";
import Row from "../../presentational/Row";

export default function FindToolsModal(props) {
  const {
    tool: { requestedToolListModalVisible, requestedToolList, configFilter },
    leap: { uploadType, uploadEngineModel },
  } = useStoreState((state) => state);

  const {
    setRequestedToolListAction,
    setRequestedToolListModalVisibleAction,
    getClientConfigurationToolListThunk,
    setCurrentConfigGroupedPageAction,
    setIsApplyingFindToolFilterAction,
  } = useStoreActions((actions) => actions);

  return (
    <CustomModal
      style={{ width: 800 }}
      width="50%"
      height="50%"
      title="Find Tools"
      onClose={() => setRequestedToolListModalVisibleAction(false)}
      isVisible={requestedToolListModalVisible}
    >
      <Input
        width="99%"
        label="Tool String"
        placeholder="Enter Tool String"
        onChangeText={(name) => setRequestedToolListAction(name)}
        value={requestedToolList}
        editable={true}
        numOfLines={5}
        multiline={true}
        style={{ height: vhToDp(20), textAlignVertical: "top" }}
      />

      <Row>
        <Col xs={12} md={6}>
          <Button
            color="#00205B"
            fullWidth
            onPress={async () => {
              // let requestedToolList =
              // 	"956A3629G01 956A3629G01 9401M62G02 9401M84G05 9401M84G05 ";
              setCurrentConfigGroupedPageAction(1);
              getClientConfigurationToolListThunk({
                OEM: uploadType,
                engineFamilyID:
                  uploadEngineModel == "All" ? "" : uploadEngineModel,
                enginevariantid: configFilter == "All" ? "" : configFilter,
                requestedToolList: requestedToolList
                  .trim()
                  .split(/[\n]+/)
                  .filter((item) => item)
                  .join(","),
              });
              setIsApplyingFindToolFilterAction(true);
              setRequestedToolListModalVisibleAction(false);
            }}
          >
            Find
          </Button>
        </Col>

        <Col xs={12} md={6}>
          <Button
            color="#00205B"
            fullWidth
            onPress={() => {
              setRequestedToolListAction("");
              setCurrentConfigGroupedPageAction(1);
              getClientConfigurationToolListThunk({
                OEM: uploadType,
                engineFamilyID:
                  uploadEngineModel == "All" ? "" : uploadEngineModel,
                enginevariantid: configFilter == "All" ? "" : configFilter,
                requestedToolList: "",
              });
              setIsApplyingFindToolFilterAction(false);
              setRequestedToolListModalVisibleAction(false);
            }}
          >
            Clear
          </Button>
        </Col>
      </Row>
    </CustomModal>
  );
}
