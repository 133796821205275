import { createFilterHeaders } from '../../utilities/filter';

const masterToolUpsertInput = {
  id: null,
  OEMID: null,
  EngineFamilyID: null,
  partNumber: '',
  partDescription: '',
  TSCode: '',
  FandFCode: '',
  LastRevision: null,
  QualifiedRevision: null,
  SupersededReplaceBy: null,
  Comments: '',
  EngineVariant: '',
  internalComments: '',
  ServiceBulletin: null,
  overrideTSPartNumber: '',
  overrideTSCode: '',
  basepartNumber: '',
};

const mergeToolUpsertInput = {
  OEMID: null,
  EngineFamilyID: null,
  partNumber: '',
  partDescription: '',
  TSCode: '',
  FandFCode: '',
  LastRevision: null,
  QualifiedRevision: null,
  SupersededReplaceBy: null,
  Comments: '',
  EngineVariant: '',
  internalComments: '',
  ServiceBulletin: null,
  PreviousTSCode: '',
  overrideTSPartNumber: '',
  overrideTSCode: '',
};

const updateMasterToolEngineInput = {
  id: '',
  engine: '',
  overrideTSPartNumber: '',
  overrideTSCode: '',
};

const upsertToolStatusInput = {
  id: '',
  OEMid: '',
  EngineFamilyID: '',
  TSCode: '',
  TSDescription: '',
  istoolUsable: false,
};

const defaultState = {
  list: [],
  searchInput: '',
  input: {
    OEM: '',
  },
  configFilter: '',
  reviewList: [],
  reviewListModalActive: false,
  fileUploadedModalVisible: false,
  deltaList: [],
  missingList: [],
  clientConfigurationList: [],
  clientConfigGroupedList: [],
  masterList: [],
  requestedToolList: '',
  requestedToolListModalVisible: false,
  exceptionList: [],
  masterListSearchInput: '',
  enteredMasterListSearchInput: '',
  clientConfigurationSearchInput: '',
  enteredClientConfigurationSearchInput: '',
  deltaListSearchInput: '',
  selectedToolDetails: [],

  masterToolInput: masterToolUpsertInput,
  mergeToolInput: mergeToolUpsertInput,

  currentReviewIndex: 0,
  masterToolIsEditable: false,
  areYouSureReviewModalVisible: false,
  areYouSureRemoveModalVisible: false,
  areYouSureAddModalVisible: false,
  missingListSearchInput: '',
  exceptionListSearchInput: '',

  selectedEngineVariants: [],
  statusList: [],
  oemStatusList: [],
  statusListByOEM: [],
  selectedStatusOEM: '',

  isComparingColumnFlagsToolStatus: [false, false],
  chosenColumnToSortIndexToolStatus: -1,
  filterToolStatusValues: ['', '', ''],
  chosenToolStatusComparisonIndexes: [-1, -1, -1],
  groupedList: [],
  statusListSearchInput: '',
  engineVariantDropdownOptions: [],
  toolStatusInput: upsertToolStatusInput,

  isComparingColumnFlagsMaster: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  chosenColumnToSortIndexMaster: -1,
  filterMasterValues: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  chosenMasterComparisonIndexes: [
    -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
  ],

  showMissingList: false,
  dynamicLoadingModalVisible: false,

  failedToImportMasterToolList: 0,
  totalToolsToImport: 0,
  totalToolsImported: 0,
  totalToolsNotImported: 0,
  masterToolGroupedList: [],
  selectedMasterTool: {},
  editMasterToolEngineModalVisible: false,

  masterToolHeaders: createFilterHeaders([
    {
      columnName: 'OEM',
      propertyName: 'OEM',
      filterPropertyValueName: 'OEM',
      filterPropertyFlagName: 'OEMFLAG',
    },
    {
      columnName: 'Part Number',
      propertyName: 'partNumber',
      filterPropertyValueName: 'PN',
      filterPropertyFlagName: 'PNFLAG',
    },
    {
      columnName: 'Part Description',
      propertyName: 'partDescription',
      filterPropertyValueName: 'PDESC',
      filterPropertyFlagName: 'PDESCFLAG',
    },
    {
      columnName: 'Engine',
      propertyName: 'ENGINE',
      showMultipleEngines: true,
      filterPropertyValueName: 'ENGINE',
      filterPropertyFlagName: 'ENGINEFLAG',
    },
    {
      columnName: 'Status Code',
      propertyName: 'TSCode',
      showMultipleEngines: true,
      filterPropertyValueName: 'SCODE',
      filterPropertyFlagName: 'SCODEFLAG',
    },

    {
      columnName: 'Qualification',
      propertyName: 'QualifiedRevision',
      showMultipleEngines: true,
      filterPropertyValueName: 'QU',
      filterPropertyFlagName: 'QUFLAG',
    },
    {
      columnName: 'CRTL REPLACEMENT',
      subheaders: [
        {
          columnName: 'Part Number',
          propertyName: 'SupersededReplacedby',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRPN',
          filterPropertyFlagName: 'CRPNFLAG',
        },
        {
          columnName: 'Status Code',
          propertyName: 'SupersededReplacedbyTSCode',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRSC',
          filterPropertyFlagName: 'CRSCFLAG',
        },
        {
          columnName: 'Qualification',
          propertyName: 'SupersededReplacedbyQualification',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRQU',
          filterPropertyFlagName: 'CRQUFLAG',
        },
      ],
    },
    {
      columnName: 'RHINESTAHL OVERRIDE',
      subheaders: [
        {
          columnName: 'Part Number',
          propertyName: 'overridePartNumber',
          showMultipleEngines: true,
          filterPropertyValueName: 'RPN',
          filterPropertyFlagName: 'RPNFLAG',
        },
        {
          columnName: 'Status Code',
          propertyName: 'overrideTSCode',
          showMultipleEngines: true,
          filterPropertyValueName: 'RSC',
          filterPropertyFlagName: 'RSCFLAG',
        },
        {
          columnName: 'Qualification',
          propertyName: 'overridequalification',
          showMultipleEngines: true,
          filterPropertyValueName: 'RQU',
          filterPropertyFlagName: 'RQUFLAG',
        },
      ],
    },
    {
      columnName: 'Edit',
      edit: true,
    },
  ]),

  masterToolGroupedPage: 1,

  updateMasterToolEngineInput: updateMasterToolEngineInput,

  editEngineIndex: -1,
  isComparingColumnFlagsDelta: [false, false],
  chosenColumnToSortIndexDelta: -1,
  filterDeltaValues: ['', '', '', '', '', '', ''],
  chosenDeltaComparisonIndexes: [-1, -1, -1, -1, -1, -1],

  currentConfigGroupedPage: 1,
  isApplyingFindToolFilter: false,
  clientConfigToolHeaders: createFilterHeaders([
    {
      columnName: 'OEM',
      propertyName: 'oem',
      filterPropertyValueName: 'OEM',
      filterPropertyFlagName: 'OEMFLAG',
    },
    {
      columnName: 'Part Number',
      propertyName: 'partNumber',
      filterPropertyValueName: 'PN',
      filterPropertyFlagName: 'PNFLAG',
    },
    {
      columnName: 'Part Description',
      propertyName: 'PartDescription',
      filterPropertyValueName: 'PDESC',
      filterPropertyFlagName: 'PDESCFLAG',
    },
    {
      columnName: 'Engine',
      propertyName: 'ENGINE',
      showMultipleEngines: true,
      filterPropertyValueName: 'ENGINE',
      filterPropertyFlagName: 'ENGINEFLAG',
    },
    {
      columnName: 'Status Code',
      propertyName: 'toolStatusCode',
      showMultipleEngines: true,
      filterPropertyValueName: 'SCODE',
      filterPropertyFlagName: 'SCODEFLAG',
    },

    {
      columnName: 'Qualification',
      propertyName: 'QualifiedRevision',
      showMultipleEngines: true,
      filterPropertyValueName: 'QU',
      filterPropertyFlagName: 'QUFLAG',
    },
    {
      columnName: 'CRTL REPLACEMENT',
      subheaders: [
        {
          columnName: 'Part Number',
          propertyName: 'LatestpartNumber',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRPN',
          filterPropertyFlagName: 'CRPNLAG',
        },
        {
          columnName: 'Status Code',
          propertyName: 'LatestpartNumberStatus',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRSC',
          filterPropertyFlagName: 'CRSCFLAG',
        },
        {
          columnName: 'Qualification',
          propertyName: 'Latestqualification',
          showMultipleEngines: true,
          filterPropertyValueName: 'CRQU',
          filterPropertyFlagName: 'CRQUFLAG',
        },
      ],
    },
    {
      columnName: 'RHINESTAHL OVERRIDE',
      subheaders: [
        {
          columnName: 'Part Number',
          propertyName: 'overridePartNumber',
          showMultipleEngines: true,
          filterPropertyValueName: 'RPN',
          filterPropertyFlagName: 'RPNFLAG',
        },
        {
          columnName: 'Status Code',
          propertyName: 'overrideTSCode',
          showMultipleEngines: true,
          filterPropertyValueName: 'RSC',
          filterPropertyFlagName: 'RSCFLAG',
        },
        {
          columnName: 'Qualification',
          propertyName: 'overridequalification',
          showMultipleEngines: true,
          offsetMenuPosHorizontal: -100,
          filterPropertyValueName: 'RQU',
          filterPropertyFlagName: 'RQUFLAG',
        },
      ],
    },
  ]),
  masterToolEnginesToRemove: [],
  tenantInfoByPartNumberList: [],
  tenantInfoByPartNumberModalVisible: false,
  tenantInfoHeaders: createFilterHeaders([
    {
      columnName: 'Tenant Name',
      propertyName: 'tenantname',
      filterPropertyValueName: 'tenantname',
      filterPropertyFlagName: 'TENANTNAMEFLAG',
    },
    {
      columnName: 'Rhinestahl Contact',
      propertyName: 'rhinestahlcontact',
      filterPropertyValueName: 'rhinestahlcontact',
      filterPropertyFlagName: 'RHINESTAHLCONTACTFLAG',
    },
    {
      columnName: 'Status Code',
      propertyName: 'statuscode',
      filterPropertyValueName: 'statuscode',
      filterPropertyFlagName: 'STATUSCODEFLAG',
    },
  ]),
  currTenantInfoPageNumber: 1,
};
const state = {
  tool: defaultState,
};

export { state as default, defaultState };
