import { View } from "react-native";
import styled from "styled-components/native";
import Button from "../../presentational/Button";
import Page from "../../containers/Page";
import React, { useEffect } from "react";
import Gap from "../../presentational/Gap";
import List from "../../functional/List";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReviewToolsModal from "../../containers/Modal/ReviewToolsModal";
import FileUploadedModal from "../../containers/Modal/FileUploadedModal";
import { useIsFocused } from "@react-navigation/native";
import FindToolsModal from "../../containers/Modal/FindToolsModal";
import Card from "../../presentational/Card";
import Row from "../../presentational/Row";
import Input from "../../presentational/Input";
import Col from "../../presentational/Col";
import SearchBar from "../../presentational/SearchBar";
import { useClientConfigToolListControlForm } from "../../../hooks/client-config";

const Container = styled(View)`
  flex: 1;
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
`;

function ConfigurationPage(props) {
  const {
    tool: { configFilter, requestedToolList },
    leap: { uploadType, uploadEngineModel },
  } = useStoreState((state) => state);
  const {
    getClientConfigurationToolListThunk,
    getOEMListThunk,
    getEngineFamilyListThunk,
    exportClientConfigurationCsvThunk,
    getOEMToEngineFamilyListThunk,
    getUserAccessControlThunk,
  } = useStoreActions((actions) => actions);

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      getClientConfigurationToolListThunk({
        OEM: uploadType,
        engineFamilyID: uploadEngineModel,
        enginevariantid: configFilter,
        requestedToolList: requestedToolList
          .trim()
          .split(/[\n]+/)
          .filter((item) => item)
          .join(","),
      });
      getOEMListThunk();
      getEngineFamilyListThunk();
      getOEMToEngineFamilyListThunk();
      getUserAccessControlThunk();
    }
  }, [isFocused]);

  const {
    engineModelInput,
    configInput,
    oemInput,
    button: { children: buttonChildren, ...buttonProps },
    searchBar,
    ...inputProps
  } = useClientConfigToolListControlForm();

  return (
    <>
      <Page.Protected {...props}>
        <Container>
          <FindToolsModal />
          <ReviewToolsModal />
          <FileUploadedModal navigation={props.navigation} />
          <Gap />
          <Card>
            <SRow>
              <Col xs={12} md={2.33}>
                <Input {...inputProps} {...engineModelInput} />
              </Col>

              <Col xs={12} md={2.34}>
                <Input {...inputProps} {...configInput} />
              </Col>
              <Col xs={12} md={2.33}>
                <Input {...inputProps} {...oemInput} />
              </Col>
              <Col xs={12} md={2}>
                <Button {...buttonProps}>{buttonChildren}</Button>
              </Col>

              <Col xs={12} md={3}>
                <SearchBar {...searchBar} />
              </Col>
            </SRow>
          </Card>

          <Gap />

          <Card>
            <List.ClientConfigTool {...props} />
          </Card>
          <Gap />
        </Container>
      </Page.Protected>

      <View
        style={{
          position: "absolute",
          right: 20,
          bottom: 20,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Button onPress={() => exportClientConfigurationCsvThunk()}>
          Generate CSV
        </Button>
      </View>
    </>
  );
}

export default ConfigurationPage;
