import {
  Pagination,
  Input,
  Center,
  Menu,
  Row,
  Pressable,
} from '@cfbs/cfbsstrap-native';
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { View, ActivityIndicator } from 'react-native';
import Table from './Table';
import { vhToDp } from '../../utilities/responsive';
import Text from './Text';
import { useStoreState } from 'easy-peasy';
import Button from './Button';
import TableColumn from './Table/TableColumn';

export default function PaginatedList({
  headers = [],
  list = [],
  height,
  maxHeight,
  isComparingColumnFlags = [false, false],
  setIsComparingColumnFlags,

  setChosenColumnToSortIndex,
  chosenColumnToSortIndex,
  filterValues,
  setFilterValues,
  chosenComparisonIndexes,
  setChosenComparisonIndexes,
  pagination = true,
  ...props
}) {
  const [filteredList, setFilteredList] = useState(list.slice());

  const { loading } = useStoreState((state) => state.status);

  const [localLoading, setLocalLoading] = useState(true); // Local loading state

  useEffect(() => {
    if (list.length > 0) {
      setLocalLoading(false);
    } else {
      const timer = setTimeout(() => {
        setLocalLoading(false);
      }, 2000); // 2000 milliseconds = 2 seconds

      return () => clearTimeout(timer);
    }
  }, [list]); // Depend on list

  const isLoading = loading || localLoading;

  const pageSize = 10;

  const [showMenus, setShowMenus] = useState(headers.map((header) => false));
  const [currentPage, setCurrentPage] = useState(0);
  const [chosenSortIndex, setChosenSortIndex] = useState(-1);

  const startIndex = currentPage * pageSize;
  const paginatedRows = filteredList.slice(startIndex, startIndex + pageSize);

  const lastPageNumDisplay = Math.ceil(filteredList.length / pageSize || 1);
  const lastPageIndex = lastPageNumDisplay - 1;

  function showMenu(columnIndex) {
    setShowMenus(
      showMenus.map((value, index) => (index === columnIndex ? true : value))
    );
  }

  function hideMenu(columnIndex) {
    setShowMenus(
      showMenus.map((value, index) => (index === columnIndex ? false : value))
    );
  }

  function isSortingColumn(columnIndex) {
    return chosenColumnToSortIndex === columnIndex;
  }

  function isComparingColumn(columnIndex) {
    return isComparingColumnFlags[columnIndex];
  }

  function sort(jsonA, jsonB, propertyName) {
    const stringA = jsonA[propertyName].toLowerCase();
    const stringB = jsonB[propertyName].toLowerCase();

    if (stringA < stringB) {
      return -1;
    } else if (stringA > stringB) {
      return 1;
    }

    return 0;
  }

  function filterList() {
    let newList = list.slice();

    if (chosenColumnToSortIndex > -1) {
      const propertyName = headers[chosenColumnToSortIndex].propertyName;
      chosenSortIndex === 0 &&
        (newList = newList.sort((jsonA, jsonB) =>
          sort(jsonA, jsonB, propertyName)
        ));
      chosenSortIndex === 1 &&
        (newList = newList.sort((jsonA, jsonB) =>
          sort(jsonB, jsonA, propertyName)
        ));
    }

    for (let i = 0; i < chosenComparisonIndexes.length; i++) {
      const comparisonIndex = chosenComparisonIndexes[i];
      const propertyName = headers[i].propertyName;
      if (isComparingColumnFlags[i]) {
        comparisonIndex === 0 &&
          (newList = newList.filter(
            (json) =>
              json[propertyName].toLowerCase() === filterValues[i].toLowerCase()
          ));
        comparisonIndex === 1 &&
          (newList = newList.filter((json) =>
            json[propertyName]
              .toLowerCase()
              .includes(filterValues[i].toLowerCase())
          ));
      }
    }
    // setCustomList(newList);

    return newList;
  }

  useEffect(() => {
    setFilteredList(filterList());
  }, [
    chosenSortIndex,
    chosenColumnToSortIndex,
    isComparingColumnFlags,
    list,
    chosenComparisonIndexes,
  ]);

  // Call the function when needed
  return (
    <>
      {isLoading ? (
        <ActivityIndicator size='large' color='#0088ce' />
      ) : (
        <>
          <Table border hover striped>
            <Table.tr fontFamily='Barlow_600SemiBold'>
              {headers.map(({ columnName, propertyName }, columnIndex) => (
                <TableColumn
                  key={columnIndex}
                  headers={headers}
                  propertyName={propertyName}
                  isComparingColumn={isComparingColumn}
                  isSortingColumn={isSortingColumn}
                  columnIndex={columnIndex}
                  columnName={columnName}
                  showMenus={showMenus}
                  showMenu={showMenu}
                  hideMenu={hideMenu}
                  setIsComparingColumnFlags={setIsComparingColumnFlags}
                  chosenComparisonIndexes={chosenComparisonIndexes}
                  setChosenComparisonIndexes={setChosenComparisonIndexes}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  setChosenColumnToSortIndex={setChosenColumnToSortIndex}
                  setChosenSortIndex={setChosenSortIndex}
                  setCurrentPage={setCurrentPage}
                  setFilteredList={setFilteredList}
                  filterList={filterList}
                  isComparingColumnFlags={isComparingColumnFlags}
                  chosenSortIndex={chosenSortIndex}
                  width={props.width}
                />
              ))}
            </Table.tr>

            {paginatedRows.map((item, rowIndex) => (
              <Table.tr
                key={rowIndex}
                striped={rowIndex % 2 === 0}
                hover
                fontFamily='Barlow_600SemiBold'
              >
                {Object.keys(item).map((key, columnIndex) => (
                  <View
                    style={{
                      width: props.width
                        ? props.width[columnIndex]
                        : `${100 / headers.length}%`,
                      height: 60,
                    }}
                    key={columnIndex}
                  >
                    <Table.td border key={key} width={props.width}>
                      {item[key]}
                    </Table.td>
                  </View>
                ))}
              </Table.tr>
            ))}
          </Table>

          <View
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            {pagination && (
              <Center>
                <Pagination
                  style={{
                    marginTop: 20,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Pagination.Item onPress={() => setCurrentPage(0)}>
                    <Pagination.First />
                  </Pagination.Item>

                  <Pagination.Item
                    onPress={() => setCurrentPage(Math.max(0, currentPage - 1))}
                  >
                    <Pagination.Previous />
                  </Pagination.Item>
                  <Pagination.Item>
                    <View
                      style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                      <Text style={{ marginRight: 10 }}>Page</Text>
                      <Input
                        style={{
                          borderWidth: 1,
                          borderColor: '#000',
                          padding: 5,
                          width: 50,
                          textAlign: 'center',
                        }}
                        keyboardType='numeric'
                        placeholder={currentPage + 1 + ''}
                        maxLength={Math.floor(
                          Math.log10(lastPageNumDisplay) + 1
                        )}
                        onChangeText={(text) => {
                          // Convert input text to an integer
                          const pageNumber = parseInt(text);

                          // Check if the pageNumber is a number, within the allowed range, and does not exceed the lastPageNumDisplay
                          if (
                            !isNaN(pageNumber) && // Check if it's a valid number
                            pageNumber >= 1 && // Check if it's not below the minimum
                            pageNumber <= lastPageNumDisplay // Check if it's not above the maximum
                          ) {
                            setCurrentPage(pageNumber - 1); // Update the currentPage, -1 because pages are 0-indexed
                          } else if (pageNumber > lastPageNumDisplay) {
                            // If the user tries to enter a page number greater than the last page, reset to the last page
                            setCurrentPage(lastPageNumDisplay - 1);
                          }
                        }}
                      />
                      <Text style={{ marginLeft: 10 }}>
                        of {lastPageNumDisplay}
                      </Text>
                    </View>
                  </Pagination.Item>

                  <Pagination.Item
                    onPress={() =>
                      setCurrentPage(Math.min(currentPage + 1, lastPageIndex))
                    }
                  >
                    <Pagination.Next />
                  </Pagination.Item>

                  <Pagination.Item
                    onPress={() => setCurrentPage(lastPageIndex)}
                  >
                    <Pagination.Last />
                  </Pagination.Item>
                </Pagination>
              </Center>
            )}
          </View>
        </>
      )}
    </>
  );
}
