import Page from '../../containers/Page';
import React, { useEffect } from 'react';

import { View } from 'react-native';
import styled from 'styled-components/native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Row from '../../presentational/Row';
import Gap from '../../presentational/Gap';
import Col from '../../presentational/Col';
import ToolStatusList from '../../containers/List/ToolStatusList';
import Button from '../../presentational/Button';
import Input from '../../presentational/Input';

const Container = styled(View)`
  padding: 2vh 4vw;
`;

const SRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

function ToolStatusListPage(props) {
  const { oemStatusList, selectedStatusOEM } = useStoreState(
    (state) => state.tool
  );
  const { getToolStatusListThunk, filterToolStatusListAction } =
    useStoreActions((actions) => actions);
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  useEffect(() => {
    if (isFocused) {
      getToolStatusListThunk();
    }
  }, [isFocused]);

  return (
    <Page.Protected {...props}>
      <Container>
        <SRow>
          <Col xs={12} sm={2}>
            <Input
              label='OEM'
              placeholder='Enter OEM'
              value={selectedStatusOEM}
              onSelect={(oem) => filterToolStatusListAction(oem)}
              dropdownChoices={oemStatusList}
              selectedItem={(option) => option}
              rowTextForSelection={(option) => option}
              dropdown
              editable
            />
          </Col>
          <Col xs={12} md={2}>
            <Button
              fullWidth
              onPress={() => {
                navigation.push('Add Tool Status');
              }}
            >
              Add Status
            </Button>
          </Col>
        </SRow>
        <Gap />
        <ToolStatusList />
        <Gap />
      </Container>
    </Page.Protected>
  );
}

export default ToolStatusListPage;
