import React, { useEffect } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import DrawerC from "../containers/Drawer";
import { useStoreState } from "easy-peasy";
import Pages from "../pages";
import { createStackNavigator } from "@react-navigation/stack";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const OEMToEngineFamilyStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				name="OEM Engine Management"
				component={Pages.OEMEngineFamilyManagement}
				options={{
					title: "OEM Engine Management",
					headerShown: false,
				}}
			/>

			<Stack.Screen
				name="Add OEM to Engine Family"
				component={Pages.AddOEMEngineFamilyManagement}
				options={{
					title: "Add OEM to Engine Family",
				}}
			/>

			<Stack.Screen
				name="Add Engine Family"
				component={Pages.AddEngineFamily}
				options={{
					title: "Add Engine Family",
				}}
			/>
			<Stack.Screen
				name="Add Engine Variant"
				component={Pages.AddEngineVariant}
				options={{
					title: "Add Engine Variant",
				}}
			/>

			<Stack.Screen
				name="Edit OEM to Engine Family"
				component={Pages.EditOEMEngineFamilyManagement}
				options={{
					title: "Edit OEM to Engine Family",
				}}
			/>

			<Stack.Screen
				name="Add OEM"
				component={Pages.AddOEM}
				options={{
					title: "Add OEM",
				}}
			/>
		</Stack.Navigator>
	);
};

const ToolStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				component={Pages.LeapMasterList}
				name="Master Tool List"
				options={{
					title: "Master Tool List",
					headerShown: false,
				}}
			/>
			<Stack.Screen
				component={Pages.AddMasterTool}
				name="Add Master Tool"
				options={{
					title: "Add Master Tool",
				}}
			/>
			<Stack.Screen
				component={Pages.EditMasterTool}
				name="Edit Master Tool"
				options={{
					title: "Edit Master Tool",
				}}
			/>
		</Stack.Navigator>
	);
};

const ToolStatusStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				component={Pages.ToolStatusList}
				name="Tool Status"
				options={{
					title: "Tool Status",
					headerShown: false,
				}}
			/>
			<Stack.Screen
				component={Pages.AddToolStatus}
				name="Add Tool Status"
				options={{
					title: "Add Tool Status",
				}}
			/>
			<Stack.Screen
				component={Pages.EditToolStatus}
				name="Edit Tool Status"
				options={{
					title: "Edit Tool Status",
				}}
			/>
		</Stack.Navigator>
	);
};

const WorkScopeStack = () => {
	return (
		<Stack.Navigator>
			<Stack.Screen
				component={Pages.WorkScopeList}
				name="Work Scope"
				options={{
					title: "Work Scope",
					headerShown: false,
				}}
			/>
			<Stack.Screen
				component={Pages.AddWorkScope}
				name="Add Work Scope"
				options={{
					title: "Add Work Scope",
				}}
			/>
			<Stack.Screen
				component={Pages.EditToolStatus}
				name="Edit Tool Status"
				options={{
					title: "Edit Tool Status",
				}}
			/>
		</Stack.Navigator>
	);
};

export default function AppDrawerNavigator() {
	// const { email, role } = useStoreState(
	// 	(state) => state.user.authenticatedUser
	// );
	const { user } = useStoreState((state) => state);

	const [route, setRoute] = React.useState("Home");

	useEffect(() => {
		if (user?.authenticatedUser?.role === "admin") {
			setRoute("Home");
		} else {
			setRoute("Client Configuration");
		}
	}, [user]);

	return (
		<NavigationContainer>
			<Drawer.Navigator
				screenOptions={{
					headerShown: user?.authenticatedUser?.email ? true : false,
					headerRight: DrawerC.Header,
				}}
				drawerContent={DrawerC.Content}
				initialRouteName={route}
			>
				<Drawer.Screen
					name="Home"
					component={Pages.Home}
					options={{
						title: "",
					}}
				/>

				<Drawer.Screen
					name="Edit User"
					component={Pages.EditUser}
					options={{
						title: "Edit User",
					}}
				/>

				{user.authenticatedUser.role === "admin" && (
					<>
						<Drawer.Screen
							name="Add User"
							component={Pages.AddUser}
							options={{
								title: "Create User",
							}}
						/>

						<Drawer.Screen
							name="Roles"
							component={Pages.RoleList}
							options={{
								title: "Roles",
							}}
						/>
						<Drawer.Screen
							name="Add Role"
							component={Pages.AddRole}
							options={{
								title: "Add Role",
							}}
						/>
						<Drawer.Screen
							name="Edit Role"
							component={Pages.EditRole}
							options={{
								title: "Edit Role",
							}}
						/>
					</>
				)}

				<Drawer.Screen
					name="Master Tool List"
					component={ToolStack}
					options={{
						title: "Master Tool List",
						// headerShown: false,
					}}
				/>

				{user.authenticatedUser.role === "admin" && (
					<Drawer.Screen
						name="CRTL Mapping Configuration"
						component={Pages.ColumnConfiguration}
						options={{
							title: "CRTL Mapping Configuration",
						}}
					/>
				)}

				<Drawer.Screen
					name="Client Configuration"
					component={Pages.Configuration}
					options={{
						title: "Client Configuration",
					}}
				/>

				{user.authenticatedUser.role === "admin" && (
					<>
						<Drawer.Screen
							name="Tool Delta"
							component={Pages.ToolDelta}
							options={{
								title: "Tool Delta",
							}}
						/>
						<Drawer.Screen
							name="Tool Status"
							component={ToolStatusStack}
							options={{
								title: "Tool Status",
							}}
						/>
					</>
				)}

				<Drawer.Screen
					name="Work Scope Tool List"
					component={Pages.MasterWorkScopeList}
				/>

				{user.authenticatedUser.role === "admin" && (
					<>
						<Drawer.Screen
							name="Tool Exception List"
							component={Pages.ExceptionToolList}
							options={{
								title: "Tool Exception List",
							}}
						/>

						<Drawer.Screen
							name="OEM Engine Management"
							component={OEMToEngineFamilyStack}
							options={{
								title: "OEM Engine Management",
							}}
						/>
					</>
				)}

				{user.authenticatedUser.role !== "user" && (
					<Drawer.Screen
						name="Push Notifications"
						component={Pages.PushNotifications}
						options={{
							title: "Push Notifications",
						}}
					/>
				)}
			</Drawer.Navigator>
		</NavigationContainer>
	);
}
