import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";
import { useStoreActions, useStoreState } from "easy-peasy";
import Switch from "../../../presentational/Switch";
import Form from "../Form";
import { CheckBox } from "@rneui/themed";
import Text from "../../../presentational/Text";
import { Divider } from "react-native-elements";
import Gap from "../../../presentational/Gap";
import { FontAwesome5 } from "@expo/vector-icons";
import { remToDp } from "../../../../utilities/responsive";

function UserForm({ buttons = [], ...props }) {
  const {
    role,
    user,
    file: { oemList, engineFamilyList, engineVariantList },
  } = useStoreState((state) => state);

  const {
    setUserInputAction,
    setUpdatedOEMListAction,
    setUpdatedEngineFamilyListAction,
    setUpdatedEngineVariantListAction,
  } = useStoreActions((actions) => actions);

  const {
    updatedOEMList,
    updatedEngineFamilyList,
    updatedEngineVariantList,
    accessControlList,
  } = user;

  console.log(updatedOEMList);
  return (
    <Form {...props}>
      <Row>
        <Col xs={12} md={6} lg={4}>
          <Input
            label="First Name"
            onChangeText={(firstName) => setUserInputAction({ firstName })}
            value={user.input.firstName}
            required
            editable={user.allowEditUser}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Input
            label="Last Name"
            onChangeText={(lastName) => setUserInputAction({ lastName })}
            value={user.input.lastName}
            required
            editable={user.allowEditUser}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <Input
            label="Username"
            onChangeText={(userName) =>
              user.authenticatedUser?.role === "admin" &&
              setUserInputAction({ userName })
            }
            value={user.input.userName}
            required
            editable={
              user.allowEditUser && user.authenticatedUser?.role === "admin"
            }
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label="Email"
            onChangeText={(email) =>
              user.authenticatedUser?.role === "admin" &&
              setUserInputAction({ email })
            }
            value={user.input.email}
            required
            editable={
              user.allowEditUser && user.authenticatedUser?.role === "admin"
            }
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            onSelect={(role) =>
              user.allowEditUser && user.authenticatedUser?.role === "admin"
                ? setUserInputAction({ role: role.name })
                : {}
            }
            rowTextForSelection={(role) => role.name}
            label="Role"
            dropdownChoices={role.list}
            dropdown
            value={user.input.role}
            selectedItem={(role) =>
              user.allowEditUser ? role.name : user.input.role
            }
            editable={
              user.allowEditUser && user.authenticatedUser?.role === "admin"
            }
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label="Password"
            onChangeText={(password) => setUserInputAction({ password })}
            value={user.input.password}
            secureTextEntry
            required={!user.input.id}
            editable={user.allowEditUser}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            label="Confirm Password"
            onChangeText={(cpassword) => setUserInputAction({ cpassword })}
            value={user.input.cpassword}
            secureTextEntry
            required={!user.input.id}
            editable={user.allowEditUser}
          />
        </Col>
      </Row>
      <Gap />
      {user.authenticatedUser?.role === "admin" && (
        <>
          <Divider />
          <Gap />
          <Col>
            <Text
              style={{
                fontSize: 18,
              }}
            >
              User Privileges
            </Text>

            <Row
              style={{
                margin: 10,
              }}
            >
              <Col xs={12} md={4}>
                <Text
                  style={{
                    fontSize: 14,
                  }}
                >
                  OEM
                </Text>
                <Row>
                  {oemList.map((oem) => (
                    <CheckBox
                      disabled={!user.allowEditUser}
                      checkedIcon={
                        <FontAwesome5
                          name="check-square"
                          size={remToDp(1.5)}
                          color="#007AFF"
                        />
                      }
                      iconType="material-community"
                      uncheckedIcon={"checkbox-blank-outline"}
                      title={oem.OEM}
                      checked={updatedOEMList?.includes(oem.OEMID)}
                      onPress={() => {
                        if (updatedOEMList?.includes(oem.OEMID) === false) {
                          setUpdatedOEMListAction([
                            ...updatedOEMList,
                            oem.OEMID,
                          ]);
                        } else {
                          setUpdatedOEMListAction(
                            updatedOEMList?.filter(
                              (oemName) => oemName !== oem.OEMID
                            )
                          );
                        }

                        const newChosenEngineFamilyList =
                          updatedEngineFamilyList.filter((engineFamilyId) => {
                            const filteredAclList = accessControlList.filter(
                              (acl) => acl.OEMID !== oem.OEMID
                            );
                            return filteredAclList.some(
                              (acl) =>
                                acl.EngineFamilyID === engineFamilyId &&
                                updatedOEMList?.includes(acl.OEMID)
                            );
                          });

                        setUpdatedEngineFamilyListAction(
                          newChosenEngineFamilyList
                        );

                        setUpdatedEngineVariantListAction(
                          updatedEngineVariantList.filter((engineVariantId) => {
                            const engineVariantWithFamily =
                              engineVariantList.find(
                                (ev) => ev.EngineVariantID === engineVariantId
                              );

                            const familyId = newChosenEngineFamilyList.find(
                              (engineFamilyId) =>
                                engineFamilyId ===
                                engineVariantWithFamily.EngineFamilyID
                            );

                            return engineVariantWithFamily && familyId;
                          })
                        );
                      }}
                    />
                  ))}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <Text
                  style={{
                    fontSize: 14,
                  }}
                >
                  Engine Family
                </Text>
                <Row>
                  {engineFamilyList.map((oem) => (
                    <Col xs={12} sm={6} md={4}>
                      <CheckBox
                        disabled={
                          !user.allowEditUser ||
                          !accessControlList?.some(
                            (acl) =>
                              acl.EngineFamilyID === oem.EngineFamilyID &&
                              updatedOEMList?.includes(acl.OEMID)
                          )
                        }
                        checkedIcon={
                          <FontAwesome5
                            name="check-square"
                            size={remToDp(1.5)}
                            color="#007AFF"
                          />
                        }
                        iconType="material-community"
                        uncheckedIcon={"checkbox-blank-outline"}
                        title={oem.EngineFamily}
                        checked={updatedEngineFamilyList?.includes(
                          oem.EngineFamilyID
                        )}
                        onPress={() => {
                          if (
                            updatedEngineFamilyList?.includes(
                              oem.EngineFamilyID
                            ) === false
                          ) {
                            setUpdatedEngineFamilyListAction([
                              ...updatedEngineFamilyList,
                              oem.EngineFamilyID,
                            ]);
                          } else {
                            setUpdatedEngineFamilyListAction(
                              updatedEngineFamilyList?.filter(
                                (oemName) => oemName !== oem.EngineFamilyID
                              )
                            );
                          }

                          setUpdatedEngineVariantListAction(
                            updatedEngineVariantList?.filter(
                              (engineVariantId) => {
                                const filteredEngineVariantList =
                                  engineVariantList.filter(
                                    (ev) =>
                                      ev.EngineFamilyID !== oem.EngineFamilyID
                                  );

                                return filteredEngineVariantList.some(
                                  (ev) => ev.EngineVariantID === engineVariantId
                                );
                              }
                            )
                          );
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={12} md={4}>
                <Text
                  style={{
                    fontSize: 14,
                  }}
                >
                  Engine Variant
                </Text>
                <Row>
                  {engineVariantList.map((oem) => (
                    <Col xs={12} sm={6} md={4} lg={3}>
                      <CheckBox
                        disabled={
                          !user.allowEditUser ||
                          !accessControlList?.some(
                            (acl) =>
                              acl.EngineFamilyID === oem.EngineFamilyID &&
                              oem.EngineVariantID === acl.EngineVariantID
                          ) ||
                          !updatedEngineFamilyList?.includes(oem.EngineFamilyID)
                        }
                        checkedIcon={
                          <FontAwesome5
                            name="check-square"
                            size={remToDp(1.5)}
                            color="#007AFF"
                          />
                        }
                        iconType="material-community"
                        uncheckedIcon={"checkbox-blank-outline"}
                        title={oem.EngineVariant}
                        checked={updatedEngineVariantList?.includes(
                          oem.EngineVariantID
                        )}
                        onPress={(isAdmin) => {
                          if (
                            updatedEngineVariantList?.includes(
                              oem.EngineVariantID
                            ) === false
                          ) {
                            setUpdatedEngineVariantListAction([
                              ...updatedEngineVariantList,
                              oem.EngineVariantID,
                            ]);
                          } else {
                            setUpdatedEngineVariantListAction(
                              updatedEngineVariantList?.filter(
                                (oemName) => oemName !== oem.EngineVariantID
                              )
                            );
                          }
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            {/* </View> */}
          </Col>
        </>
      )}
      <Row>
        <Col xs={12}>
          <Switch
            label="Active"
            onValueChange={(active) => setUserInputAction({ active })}
            value={user.input.active}
            disabled={
              !user.allowEditUser || user.authenticatedUser?.role !== "admin"
            }
          />
        </Col>

        {buttons.map((button, index) => (
          <Col xs={12} md={12 / buttons.length} key={index}>
            {button}
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default UserForm;
