import { useEffect, useState } from "react";
import { Dimensions } from "react-native";
import styled from "styled-components/native";
import { remToDp } from "../../utilities/responsive";

const Container = styled.View`
  width: ${(props) => props.width}%;
  padding: ${remToDp(0.75)}px;
`;

function Col({ children, xs, sm, md, lg, xl, xxl, ...props }) {
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        setWindowWidth(window.width);
      }
    );
    return () => subscription?.remove();
  });

  let width = 100;
  if (windowWidth >= 1400 && xxl) width = (xxl / 12) * 100;
  if (windowWidth >= 1200 && xl) width = (xl / 12) * 100;
  else if (windowWidth >= 992 && lg) width = (lg / 12) * 100;
  else if (windowWidth >= 768 && md) width = (md / 12) * 100;
  else if (windowWidth >= 576 && sm) width = (sm / 12) * 100;
  else if (windowWidth < 576 && xs) width = (xs / 12) * 100;

  return (
    <Container width={width} {...props}>
      {children}
    </Container>
  );
}

export default Col;
