import Col from "../../../presentational/Col";
import Row from "../../../presentational/Row";
import Input from "../../../presentational/Input";

function ViewMasterToolForm({ item, buttons = [], ...props }) {
	return (
		<Row>
			<Col>
				<Input
					label="Service Bulletin"
					value={item.servicebulletin}
					editable={false}
				/>
			</Col>

			<Col>
				<Input
					label="Internal Comments"
					value={item.internalComments}
					editable={false}
				/>
			</Col>

			<Col>
				<Input label="Comments" value={item.Comments} editable={false} />
			</Col>
		</Row>
	);
}

export default ViewMasterToolForm;
