import { useStoreActions, useStoreState } from "easy-peasy";
import { FontAwesome5 } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import Card from "../../presentational/Card";
import { useNavigation } from "@react-navigation/native";
import { formatStringForComparison } from "../../../utilities/string";
import PaginatedList from "../../presentational/PaginatedList";

function UserList() {
  const {
    list,
    searchInput,
    isComparingColumnFlagsUser,
    chosenColumnToSortIndexUser,
    filterUserValues,
    chosenUserComparisonIndexes,
    authenticatedUser,
  } = useStoreState((state) => state.user);

  const {
    setIsComparingColumnFlagsUserAction,
    setChosenColumnToSortIndexUserAction,
    setFilterUserValuesAction,
    setChosenUserComparisonIndexesAction,
    setUserInputAction,
    getUserAccessControlThunk,
  } = useStoreActions((actions) => actions);

  const navigation = useNavigation();

  return (
    <Card>
      <PaginatedList
        chosenComparisonIndexes={chosenUserComparisonIndexes}
        setChosenComparisonIndexes={setChosenUserComparisonIndexesAction}
        filterValues={filterUserValues}
        setFilterValues={setFilterUserValuesAction}
        setChosenColumnToSortIndex={setChosenColumnToSortIndexUserAction}
        chosenColumnToSortIndex={chosenColumnToSortIndexUser}
        isComparingColumnFlags={isComparingColumnFlagsUser}
        setIsComparingColumnFlags={setIsComparingColumnFlagsUserAction}
        headers={[
          { columnName: "Name", propertyName: "firstName" },
          { columnName: "Email", propertyName: "email" },
          { columnName: "Status", propertyName: "status" },
          { columnName: "Edit", propertyName: "status" },
        ]}
        list={list
          .filter((user) => {
            if (
              (authenticatedUser.role !== "admin" &&
                authenticatedUser.id === user.id) ||
              authenticatedUser.role === "admin"
            ) {
              return (
                formatStringForComparison(user.firstName).includes(
                  formatStringForComparison(searchInput)
                ) ||
                formatStringForComparison(user.lastName).includes(
                  formatStringForComparison(searchInput)
                ) ||
                formatStringForComparison(user.userName).includes(
                  formatStringForComparison(searchInput)
                ) ||
                formatStringForComparison(user.email).includes(
                  formatStringForComparison(searchInput)
                ) ||
                formatStringForComparison(user.role).includes(
                  formatStringForComparison(searchInput)
                )
              );
            }

            return false;
          })
          .map((user, index) => ({
            firstName: `${user.firstName} ${user.lastName}`,
            email: user.email,
            status: user.active ? "Active" : "Inactive",
            edit: (
              <Icon
                key={index}
                Component={FontAwesome5}
                name="edit"
                onPress={() => {
                  setUserInputAction({
                    ...user,
                    oemaccess: user.oemaccess?.split(",") || [],
                    enginefamilyaccess:
                      user.enginefamilyaccess?.split(",") || [],
                    enginevariantaccess:
                      user.enginevariantaccess?.split(",") || [],
                    password: "",
                  });
                  getUserAccessControlThunk(user.id);
                  navigation.navigate("Edit User", { id: user.id });
                }}
              />
            ),
          }))}
      />
    </Card>
  );
}

export default UserList;
